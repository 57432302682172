.wd_modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  text-align: center;
  z-index: 9000; /* Don't really want this, but needed to get above fancybox */
}
.wd_modal-overlay:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}
.wd_modal-current {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.75);
}
.wd_modal-outer {
  position: relative;
  overflow: visible;
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  margin: 0;
  padding: 10px;
}
.wd_modal-inner {
  position: relative;
  overflow: auto;
}
.wd_modal {
  text-align: left;
  height: 100%;
  width: 100%;
  outline: none;
}
.wd_modal-outer .wd_modal-close {
  display: block;
  position: fixed;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  background-color: transparent;
  color: #ffffff;
  font-size: 26px;
  text-decoration: none;
}

/* Class selector repeated in order to increase specificity */
.wd_sr-only.wd_sr-only.wd_sr-only.wd_sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.wd_sr-only.wd_sr-only.wd_sr-only.wd_sr-only:active,
.wd_sr-only.wd_sr-only.wd_sr-only.wd_sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.wd_sr-only-focusable:active,
.wd_sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.wd_form_field {
  overflow: auto;
  margin: 5px;
  padding: 0;
  border: none;
}

.wd_form_field_label {
  padding: 0;
}

.wd_form_field_error {
  color: #ff0000;
}

.wd_input_border {
  display: inline-block;
  border: 0px solid transparent;
  margin: 0px;
  padding: 0px;
}

.wd_form_footer {
  margin: 5px;
}

@media (max-width: 768px) {
  .wd_form_field_label {
    text-align: left;
  }
  .wd_form_field_pos_left_inline .wd_form_field_input,
  .wd_form_field_pos_left_inline .wd_form_field_label,
  .wd_form_field_pos_right_inline .wd_form_field_input,
  .wd_form_field_pos_right_inline .wd_form_field_label {
    display: inline;
  }
}

@media (min-width: 769px) {
  .wd_form_field_pos_right .wd_form_field_label,
  .wd_form_field_pos_right_inline .wd_form_field_label,
  div.wd_form_footer div.wd_form_legend {
    float: left;
    width: 150px;
    text-align: right;
  }
  .wd_form_field_pos_left .wd_form_field_input,
  .wd_form_field_pos_left_inline .wd_form_field_input {
    float: left;
    min-width: 150px;
    text-align: right;
  }
  .wd_form_field_pos_left .wd_form_field_label,
  .wd_form_field_pos_left_inline .wd_form_field_label,
  .wd_form_field_pos_right .wd_form_field_input,
  .wd_form_field_pos_right_inline .wd_form_field_input,
  .wd_form_field_pos_above .wd_form_field_input,
  .wd_form_field_pos_above .wd_form_field_label,
  .wd_form_field_pos_below .wd_form_field_input,
  .wd_form_field_pos_below .wd_form_field_label,
  div.wd_form_footer div.wd_form_buttons {
    margin-left: 155px;
    overflow: auto;
  }
}

.wd_featureboxes-vertical .wd_form_field_input,
.wd_featureboxes-vertical .wd_form_field_label,
.wd_featureboxes-vertical div.wd_form_footer div.wd_form_legend,
.wd_featureboxes-vertical div.wd_form_footer div.wd_form_buttons {
  display: inline;
  float: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  margin-left: 0;
  overflow: auto;
}

.wd_featureboxes-vertical .wd_form_field_input input {
  max-width: 100%;
  box-sizing: border-box;
}

/* Begin classes for common SPIN elements */
*,
::before,
::after {
  box-sizing: border-box;
}
*,
::before,
::after {
  box-sizing: inherit;
}
*,
::before,
::after {
  box-sizing: border-box;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: currentColor;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
body {
  overflow-x: hidden;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
html {
  overflow-x: hidden;
}
.wd_top_link:hover {
  cursor: pointer;
}
.wd_content-wrapper-left.top-content-container {
  margin: 162.17px auto 0px auto;
  padding: 0 1.25rem;
  max-width: 1400px;
  width: 100%;
}
.wd_content-wrapper-left.body-content-container {
  margin: 0px auto 100px auto;
  padding: 0 1.25rem;
  max-width: 1400px;
  width: 100%;
}
.wd_pageid_45 .wd_content-wrapper-left {
  margin: 0px auto 0px auto;
  padding: 0 1.25rem;
  max-width: 1400px;
  width: 100%;
}
.wd_pageid_45 .breadcrumb {
  display: none;
}
.subscribe-wrapper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto 0 auto;
}

.wd_pageid_67 {
  font-family: arial, sans-serif;
  background-color: #3f4443;
  color: #fff;
}
.wd_subscribe_featurebox .wd_form_field_input {
  margin-left: 0;
}
.wd_subscribe_featurebox .wd_form_footer {
  margin: 0;
}

.wd_subscribe_featurebox div.wd_form_footer div.wd_form_buttons {
  margin-left: 0;
}
.wd_subscribe_featurebox input[type="text"] {
  height: 52px;
  border-radius: 50px;
  border: none;
  padding: 15px 15px;
}

.wd_form_buttons i.fa.fa-arrow-right-long {
  position: absolute;
  right: 17px;
  color: #000;
  top: 13px;
}

.wd_pageid_67 .wd_pageheader,
.wd_pageid_67 .wd_left-sidebar,
.wd_pageid_67 .wd_footer,
.wd_pageid_67 .wd_toolbar {
  display: none;
}

/* Header styles */
.page_header {
  margin: 0;
  font-size: clamp(1.5rem, 2.5vw, 2.5rem);
}
.wd_subscribe_link {
  display: none;
}
.category_header {
}
.detail_header,
.detail_subheader,
.category_name {
}
.category_intro {
}
.hero-block h1,
.hero-block p {
  color: #fff;
}
.hero-block .block-label {
  color: #ffc72c;
}
/* END Header styles */

.item,
.wd_item {
  margin: 10px 0;
  padding: 10px 0;
}
.item_name,
.wd_documents_item_list > .wd_documents_item > .wd_title {
  font-size: 1.2rem;
  font-weight: 700;
}
.item_date,
.wd_event_sidebar .wd_event_date,
.wd_newsfeed_releases-teaser .wd_date {
  color: #c15401;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.9375rem;
}
.wd_newsfeed_releases-teaser .wd_title a {
  color: #3f4443;
  line-height: 1.3;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
}
.item_summary,
.wd_item .wd_summary {
}
.wd_item .wd_subtitle {
}

table.wd_imagetable {
  position: absolute;
  width: 100%;
  left: 0;
  height: 50px;
  overflow: hidden;
}

.wd_imagetable img {
  width: 100%;
  height: 100%;
}

span.wd_subscribe_icon {
  /* background: url("../images/webdriver/email_alerts.png") no-repeat; */
  background-position: 0 3px;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-left: 5px;
}
span.wd_subscribe_icon img {
  display: none;
}

/* Clear Fix */
ul.wd_layout-simple:before,
ul.wd_layout-simple:after {
  content: " ";
  display: block;
}
ul.wd_layout-simple:after {
  clear: both;
}

/* Standard */
div.wd_standard {
}
.wd_pageid_20 li {
  margin: 0 0 10px 0;
}
.wd_pageid_20 li::marker {
  color: var(--endo-preset-color-dark-orange);
}
.wd_pageid_20 .wd_category_items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.wd_pageid_20 .wd_category_items .item {
  flex-basis: calc(25% - 10px);
}
.wd_pageid_20 .item_name {
  font-weight: 500;
  border-bottom: 1px solid var(--endo-preset-color-gray-40);
  padding: 0 0 20px 0;
}
/* END Standard */

/* Stories */
div.wd_stories {
}
/* END Stories */

/* Articles */
div.wd_articles {
}
/* END Articles */

/* Events */
div.wd_events {
}
.wd_event_sidebar .wd_event_date,
.wd_event_sidebar .wd_event_time,
.wd_event_sidebar .wd_event_subscribe,
.wd_event_sidebar .wd_event_reminder,
.wd_event_sidebar .wd_event_webcast {
  background-position: left;
}
.wd_event .wd_attachment_container {
  overflow: auto;
  margin: 0;
}
.wd_events-detail .wd_event .wd_attachment_container,
.wd_events_list .wd_attachment_container,
.wd_event.item .wd_event_sidebar_item {
  margin-bottom: 15px;
}
.wd_event .wd_event_sidebar .wd_event_sidebar_item {
  margin-bottom: 15px;
}
/* END Events */

/* Gallery */
div.wd_gallery {
}
/* END Gallery */

/* Newsfeed Releases */
div.wd_newsfeed_releases {
}
.wd_item .wd_date {
  margin-bottom: 10px;
  color: #c15401;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.9375rem;
}
.wd_newsfeed_releases .wd_item {
  margin: 0;
  padding: 0;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #3f4443;
}
.wd_newsfeed_releases .wd_newsfeed_releases .wd_item:last-child {
  border-bottom: none;
}
.wd_item_search_form_container {
  margin-bottom: 0;
}
.wd_item .wd_title {
  font-size: initial !important;
}
.wd_item .wd_title a {
  color: #3f4443;
  line-height: 1.3;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
}
.wd_item .wd_title a:hover {
  text-decoration: none;
}
/* END Newsfeed Releases */

/* override font size set in feed releases */
.wd_newsfeed_releases-detail .news_body p.prnews_p {
  font-family: inherit;
  font-size: 100%;
  color: inherit;
}
.wd_newsfeed_releases-detail .wd_body a {
  color: #c15401;
}
/* reset UL behavior to default in NFR body */
.wd_newsfeed_releases-detail .wd_news_body ul,
.wd_newsfeed_releases-detail .wd_news_body ul ul {
  margin: 1em 0 1em 0;
  padding-left: 40px;
}
.wd_newsfeed_releases-detail .wd_news_body li,
.wd_newsfeed_releases-detail .wd_news_body li li {
  list-style-type: disc;
  list-style: disc;
}
.wd_newsfeed_releases-detail .wd_news_body ul.wd_layout-masonry li,
.wd_newsfeed_releases-detail .wd_news_body ul.wd_layout-masonry li li {
  list-style-type: none;
  list-style: none;
}
.wd_newsfeed_releases-teaser .tools {
  text-decoration: none;
  margin-bottom: 12px;
}
/* END Newsfeed Releases */

/* START RELEASES DETAIL */
.bread-toolbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.breadcrumb-wrapper {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}
.breadcrumb-separator {
  padding: 0 4px;
}
.breadcrumb-wrapper a {
  text-decoration: none;
  color: #c15401;
}
.bread-toolbar-container .wd_date {
  display: inline;
}
.wd_newsfeed_releases-detail .wd_title {
  font-size: clamp(32px, 3vw, 40px);
  line-height: 1.2;
  font-weight: 500;
  text-transform: none;
  margin-top: 20px;
  margin-bottom: 80px;
}
.wd_newsfeed_releases-detail .wd_asset_inline_list {
  display: flex;
  flex-direction: column;
  width: auto !important;
  padding-left: 15px !important;
  height: auto !important;
}
.wd_newsfeed_releases-detail .wd_asset_inline_list .wd_asset_inline {
  position: relative !important;
  left: auto !important;
  top: auto !important;
  padding: 0 !important;
  background-color: transparent !important;
}
.wd_newsfeed_releases-detail
  .wd_asset_inline_list
  .wd_asset_inline
  .wd_gallery_asset,
.wd_newsfeed_releases-detail
  .wd_asset_inline_list
  .wd_asset_inline
  .wd_asset_image {
  height: auto !important;
}
.wd_newsfeed_releases-detail
  .wd_asset_inline_list
  .wd_asset_inline
  .wd_icon_overlay {
  height: 100% !important;
}
.wd_newsfeed_releases-detail .wd_asset_list .wd_item {
  background: none;
}
/* END RELEASES DETAIL */

/* Documents Page */
.wd_documents .wd_item_search_form_container {
  display: none;
}
.wd_documents_item_list {
  margin: 0;
  padding: 0;
}
.wd_documents_item_list .wd_documents_item {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #3f4443;
  border-top: none !important;
}
.wd_documents_item_list .wd_documents_item .wd_icon {
  display: none;
}
.wd_documents_item_list .wd_documents_item:last-child {
  border-bottom: none;
}
.wd_documents_item_list .wd_documents_item .wd_title {
  margin-bottom: 10px;
}
.wd_documents_item_list .wd_documents_item .wd_title a {
  color: #3f4443;
  line-height: 1.3;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
.wd_documents_item_list .wd_documents_item .wd_title a:hover {
  text-decoration: underline;
  color: #c15401;
}
.wd_documents_item_list .wd_documents_item .wd_summary p {
  color: #3f4443;
}
/* End Documents Page */

/* Formbuilder */
select::-ms-expand {
  border: none;
  margin-left: 50px;
  background-color: #fff;
}
#wd_printable_content input,
#wd_printable_content select {
  border-radius: 0;
  border: 1px solid #ccc;
  line-height: 34px;
  padding: 0 1rem;
  font-size: 1.2rem;
  height: 34px;
  color: #333;
}
#wd_printable_content button {
}

#wd_printable_content input[type="submit"] {
  background: var(--endo-preset-color-dark-gray);
  padding: 0.625rem 1.25rem;
  border-radius: 2.625rem;
  border: 2px solid var(--endo-preset-color-dark-gray);
  color: var(--endo-preset-color-white);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
  font-size: var(--endo-preset-font-size-small-medium);
  cursor: pointer;
  line-height: 1;
  height: unset;
}
#wd_printable_content input[type="submit"]:hover {
  border-color: #ffc72c;
  color: #000;
  border-width: 2px;
  padding: 0.625rem 2.5rem;
  background: linear-gradient(180deg, #ffcd03 0%, #ff9e1b 100%);
}

#wd_printable_content .wd_form_field_label {
  line-height: 2.4em;
  margin-bottom: 0;
}
#wd_printable_content input[type="text"] {
  max-width: 69%;
}

#wd_printable_content input[type="checkbox"],
#wd_printable_content input[type="radio"] {
  height: auto;
}
#wd_printable_content input[type="checkbox"] + label,
#wd_printable_content input[type="radio"] + label {
  display: inline-block;
  margin-bottom: 0;
  margin-left: 0.5rem;
}
.wd_form_field {
  clear: both;
}
.wd_page_limit {
  padding: 0;
}
.wd_linkmenu-header {
  padding: 6px 2px;
}
.wd_page_link:first-child,
.wd_page_link:last-child,
.wd_linkmenu-header {
  border-radius: 0;
}
.wd_linkmenu-header:hover .wd_linkmenu-icon {
  border-color: transparent;
  background-color: transparent;
  color: #000;
}
.wd_form_field .wd_form_field_input,
.wd_form_field {
  overflow: visible;
}
/* END Formbuilder */

/* Search Results */
/* END Search Results */

/* Start Newsfeed Releases */
.wd_newsfeed_releases .wd_item_list {
  margin-top: 0;
}
.wd_newsfeed_releases .wd_item .wd_thumbnail,
.wd_newsfeed_releases .wd_asset_type_link_list,
.wd_newsfeed_releases .wd_summary {
  display: none;
}
.wd_newsfeed_releases .wd_item_wrapper {
  margin-left: 0;
}
/* End Newsfeed Releases */

/* Featureboxes */
div.wd_featurebox {
}
div.wd_featurebox_container {
}
div.wd_featurebox_title {
  font-size: 2rem;
  line-height: 45px;
  font-weight: 500;
  margin: 0 0 1.875rem;
}
.quarterly-fb-bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.topFBWrapper {
  padding-left: 10%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #faf9f8;
}
.wd_featureboxes_top .wd_events-teaser {
  display: none;
}
div.wd_featureboxes_top {
}
div.wd_featureboxes_top h2 {
  margin-top: 0;
}
div.wd_featureboxes_top .swiper {
  margin-bottom: 30px;
  padding-right: 30px;
  overflow: visible;
}
div.wd_featureboxes_top .swiper-slide {
  background-color: #fff;
  border-radius: 1.25rem;
  padding: 30px 20px;
  min-height: 350px;
  position: relative;
  padding-bottom: 60px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
div.wd_featureboxes_top .wd_date_time {
  margin-bottom: 12px;
}
div.wd_featureboxes_top .wd_item {
  padding: 0;
  margin: 0;
}
div.wd_featureboxes_top .wd_featurebox_title {
  margin-bottom: 10px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #c15401;
  text-transform: uppercase;
  line-height: 1.1;
}
div.wd_featureboxes_top .wd_title a {
  font-size: clamp(1.2rem, 2.5vw, 1.4rem);
  font-weight: 600;
  line-height: 1.2;
}
div.wd_featureboxes_top .wd_separator {
  margin: 0 5px;
}
div.wd_featureboxes_top .wd_time {
  color: #c15401;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.9375rem;
}
div.wd_featureboxes_top .wd_learn_more {
  position: absolute;
  bottom: 25px;
  left: 20px;
  margin: 0;
}
div.wd_featureboxes_top .wd_learn_more a {
  color: #3f4443;
  font-weight: 500;
  text-decoration: none;
}
div.wd_featureboxes_top .wd_learn_more svg {
  margin-left: 5px;
}
div.wd_featureboxes_top div.wd_featurebox {
}
div.wd_featureboxes_top div.wd_featurebox_container {
}
div.wd_featureboxes_top div.wd_featurebox_title {
}

div.wd_featureboxes_left {
}
div.wd_featureboxes_left div.wd_featurebox {
}
div.wd_featureboxes_left div.wd_featurebox_container {
}
div.wd_featureboxes_left div.wd_featurebox_title {
}

div.wd_featureboxes_right {
}
div.wd_featureboxes_right div.wd_featurebox {
}
div.wd_featureboxes_right div.wd_featurebox_container {
}
div.wd_featureboxes_right div.wd_featurebox_title {
}

div.wd_featureboxes_bottom {
}
.wd_pageid_151 .wd_featureboxes_bottom {
  display: none;
}
div.wd_featureboxes_bottom div.wd_featurebox {
}
div.wd_featureboxes_bottom div.wd_featurebox_container {
}
div.wd_featureboxes_bottom div.wd_featurebox_title {
}
.quarterly-fb-margins {
  max-width: 1400px;
  padding: 100px 1.25rem;
  margin: 0 auto;
}
div.wd_featureboxes_quarterly .wd_featurebox_title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #c15401;
  text-transform: uppercase;
  line-height: 1.1;
}
div.wd_featureboxes_quarterly .latest-results-icon {
  margin-bottom: 10px;
}
div.wd_featureboxes_quarterly .wd_featurebox h2 {
  margin-top: 0;
  text-align: center;
  margin-bottom: 30px;
}
div.wd_featureboxes_quarterly .wd_featurebox ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-bottom: 30px;
}
div.wd_featureboxes_quarterly .wd_featurebox ul li {
  padding: 10px 30px 20px;
  position: relative;
  width: calc(100% / 5);
  text-align: center;
}
div.wd_featureboxes_quarterly .wd_featurebox ul li:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50px;
  height: 2px;
  background: #ffc72c;
  transform: translateX(-50%);
}
div.wd_featureboxes_quarterly .wd_featurebox ul li a {
  color: #3f4443;
  font-weight: 500;
  text-decoration: none;
}
div.wd_featureboxes_quarterly .wd_featurebox ul li a:hover {
  color: #c15401;
  text-decoration: underline;
}
div.wd_featureboxes_quarterly p.more {
  text-align: center;
}

/* START MIDDLE FB */
div.wd_featureboxes_middle {
  padding: 100px 80px;
}
div.wd_featureboxes_middle div.wd_featurebox {
}
div.wd_featureboxes_middle div.wd_featurebox_container {
}
div.wd_featureboxes_middle div.wd_featurebox_title {
}
div.wd_featureboxes_middle .item {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #3f4443;
}
div.wd_featureboxes_middle .wd_asset_icon_link_list,
div.wd_featureboxes_middle .wd_thumbnail,
div.wd_featureboxes_middle .subscribe_link,
div.wd_featureboxes_middle .wd_summary {
  display: none;
}
div.wd_featureboxes_middle p.more {
  margin-top: 40px;
  text-align: center;
}

div.wd_featureboxes_subscribe {
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #faf9f8;
  position: relative;
}
div.wd_featureboxes_subscribe div.wd_featurebox_title {
  margin-bottom: 5px;
}
div.wd_featureboxes_subscribe:after {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  bottom: 0;
  width: 9999px;
  height: 100%;
  background: #faf9f8;
}
div.wd_featureboxes_subscribe:before {
  content: "";
  display: block;
  position: absolute;
  right: 100%;
  bottom: 0;
  width: 9999px;
  height: 100%;
  background: #faf9f8;
}
#subscribe_form_2 {
  margin-top: 30px;
}
#subscribe_form_2 .wd_form_header {
  display: none;
}
#subscribe_form_2 .wd_form_field-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
}
#subscribe_form_2 .wd_form_field {
  margin: 0;
  width: calc((100% - 40px) / 2);
}
#subscribe_form_2 .wd_form_footer input[type="submit"],
.wd_subscribe2 .wd_form_footer input[type="submit"] {
  border-color: #3f4443;
  border-width: 2px;
  color: #3f4443;
  transition: all 0.3s;
  background-color: transparent;
}
#subscribe_form_2 .wd_form_footer input[type="submit"]:hover,
.wd_subscribe2 .wd_form_footer input[type="submit"]:hover {
  border-color: #ffc72c;
  color: #000;
  border-width: 2px;
  padding: 0.625rem 2.5rem;
  background: linear-gradient(180deg, #ffcd03 0%, #ff9e1b 100%);
}
.wd_subscribe2 .wd_form_footer {
  margin: 10px 0;
}
div.wd_featureboxes_subscribe div.wd_featurebox {
}
div.wd_featureboxes_subscribe div.wd_featurebox_container {
}
div.wd_featureboxes_subscribe div.wd_featurebox_title {
}
#subscribe_form_2 input[type="text"] {
  border: 1px solid #8f9696;
  background-color: #fff;
}
.wd_featureboxes_stock {
  display: none;
}
.stock_featurebox_wrapper {
  padding-top: 90px;
  padding-bottom: 60px;
}
.stock-ticker-wrapper div.wd_ticker_label {
  text-align: none;
}
.stock_featurebox_wrapper .stock-information {
  display: flex;
  justify-content: center;
  padding-left: 15%;
  padding-right: 15%;
}
.stock-information {
  text-align: center;
}
.stock-information p {
  margin-top: 4px;
}
.stock-ticker-wrapper .wd_exchange {
  display: none;
}
.stock-ticker-wrapper .wd_ticker_label {
  margin: 0;
  text-align: center;
}
.stock-ticker-wrapper,
.stock-ticker-price {
  border-right: 2px solid #faf9f8;
}
.stock-ticker-wrapper .wd_symbol,
.wd_chart-quote-value.last_price,
.stock-information .stock-price-change {
  font-weight: 500;
  color: #3f4443;
  font-size: clamp(1.5rem, 2.5vw, 2.5rem);
}

.stock_featurebox_wrapper .stock-information .stock-ticker-wrapper,
.stock_featurebox_wrapper .stock-information .stock-ticker-price,
.stock_featurebox_wrapper .stock-information .stock-ticker-change {
  width: 33.33%;
}
.stock_featurebox_wrapper .stock-footer .wd_chart-quote-footer {
  margin-top: 10px;
  text-align: center;
  color: #3f4443 !important;
}

/* START ALTERNATING FB */
.wd_featureboxes_middle_alternating {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #faf9f8;
  position: relative;
}
.wd_featureboxes_middle_alternating:after {
  content: "";
  display: block;
  position: absolute;
  left: 100%;
  bottom: 0;
  width: 9999px;
  height: 100%;
  background: #faf9f8;
}
.wd_featureboxes_middle_alternating:before {
  content: "";
  display: block;
  position: absolute;
  right: 100%;
  bottom: 0;
  width: 9999px;
  height: 100%;
  background: #faf9f8;
}
.wd_featureboxes_middle_alternating .wd_featurebox {
  display: flex;
}
.wd_featureboxes_middle_alternating .wd_featurebox_container {
  margin-bottom: 100px;
}
.wd_featureboxes_middle_alternating .wd_featurebox_container:last-child {
  margin-bottom: 0;
}
.wd_featureboxes_middle_alternating
  .wd_featurebox_container:nth-child(even)
  .wd_featurebox {
  flex-direction: row-reverse;
}
.wd_featureboxes_middle_alternating .wd_featurebox_image {
  position: relative;
  overflow: hidden;
  width: 50%;
  height: 0;
  padding-bottom: 39%;
}
.wd_featureboxes_middle_alternating .wd_featurebox_content {
  width: 50%;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wd_featureboxes_middle_alternating
  .wd_featurebox_container:nth-child(even)
  .wd_featurebox_content {
  padding-left: 0;
  padding-right: 100px;
}
.wd_featureboxes_middle_alternating .wd_featurebox_title {
  color: #c15401;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.1;
}
.wd_featureboxes_middle_alternating h2 {
  font-size: 2rem;
  line-height: 45px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.wd_featureboxes_middle_alternating .wd_featurebox_content p {
  margin-top: 0;
}
.wd_featureboxes_middle_alternating .wd_featurebox_image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}
.wd_featureboxes_middle_alternating p.more {
  margin-top: 15px;
}
/* END ALTERNATING FB */

/* FINANCIAL TABBED FEATUREBOX */
.wd_featureboxes_financial_tabbed {
  padding: 100px 0;
}
#wd_featurebox-teaser_6 .wd_standard-teaser,
#wd_featurebox-teaser_5 .wd_standard-teaser {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
#wd_featurebox-teaser_6 .wd_standard-teaser .item,
#wd_featurebox-teaser_5 .wd_standard-teaser .item {
  width: calc((100% - 90px) / 4);
  margin-top: 0px;
  padding-top: 0px;
}
#wd_featurebox-teaser_6 .wd_standard-teaser .item_name a,
#wd_featurebox-teaser_5 .wd_standard-teaser .item_name a {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px solid #3f4443;
  margin: 0 0 0.67em 0;
  text-decoration: none;
  color: #3f4443;
}
#wd_featurebox-teaser_6 .item ul,
#wd_featurebox-teaser_5 .item ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
#wd_featurebox-teaser_6 .item ul li,
#wd_featurebox-teaser_5 .item ul li {
  font-weight: 500;
  line-height: 1.3;
  font-size: 1.063rem;
  margin-bottom: 17px;
}
#wd_featurebox-teaser_6 .item ul li a,
#wd_featurebox-teaser_5 .item ul li a {
  text-decoration: none;
  color: #c15401;
}
#wd_featurebox-teaser_6 .item ul li:before,
#wd_featurebox-teaser_5 .item ul li:before {
  display: inline-block;
  content: "\f111";
  font-family: "FontAwesome";
  margin-right: 7px;
  font-size: 8px;
  vertical-align: middle;
  color: #c15401;
}
.wd_featureboxes_financial_tabbed .wd_featurebox_title {
  text-align: center;
  font-size: clamp(1.5rem, 2.5vw, 2.5rem);
  line-height: clamp(1.5rem, 2.5vw, 2.5rem);
  font-weight: 500;
  margin: 0 0 3rem;
}
#wd_featurebox-financial_3 .wd_filer_label {
  display: none;
}
#ir_featurebox_3-filings .wd_tr:nth-child(odd) > .wd_td {
  background-color: #faf9f8;
}
#ir_featurebox_3-filings .wd_thead {
  display: block !important;
}
#ir_featurebox_3-filings .wd_thead .wd_th {
  width: 25%;
  display: inline-block !important;
  color: #fff;
  font-weight: 400;
  padding: 5px 10px;
  border-top: none;
  border-bottom: none;
}
#ir_featurebox_3-filings .wd_thead .wd_tr {
  width: 100%;
}
#ir_featurebox_3-filings .wd_thead .wd_tr {
  width: 100% !important;
  display: inline-block;
  background-color: #3f4443;
  border-radius: 15px 15px 0 0;
}
#ir_featurebox_3-filings .wd_tr {
  width: 100%;
  display: flex;
}
#ir_featurebox_3-filings .wd_tr .wd_td {
  width: 25%;
  padding: 10px 10px;
}
#ir_featurebox_3-filings .wd_filings-format {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#ir_featurebox_3-filings .wd_filings-format a {
  font-weight: 500;
  text-decoration: none;
  color: #c15401;
}
#ir_featurebox_3-filings .mobile-header {
  display: none;
}
/* END FINANCIAL TABBED FEATUREBOX */

.wd_pageid_45 #wd_printable_content {
  display: none;
}
.wd_featureboxes_financial_tabbed .wd_tabs_wrapper {
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #3f4443;
}
.wd_featureboxes_financial_tabbed .wd_tabs {
  display: inline-flex;
  gap: 20px;
  justify-content: center;
  padding-left: 0;
  padding: 0.5625rem;
  border-radius: 3.6875rem;
  box-shadow: 0 2.82px 8.3px 0 rgba(0, 0, 0, 0.05);
  background-color: #faf9f8;
  list-style: none;
  margin: 0;
}
.wd_featureboxes_financial_tabbed .wd_tab {
  color: #3f4443;
  background-color: transparent;
  border-radius: 2.125rem;
  padding: 1.375rem 2.8125rem;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  border: none;
}
.wd_featureboxes_financial_tabbed .wd_tab.wd_tab-active {
  color: #fff;
  background-color: #3f4443;
  border-radius: 2.125rem;
  padding: 1.375rem 2.8125rem;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
}

/* END Featureboxes */

/* START SEARCH FORM */
.wd_item_search_form_container {
  margin-top: 30px;
  padding-bottom: 3.38rem;
  margin-bottom: 16px;
}
.wd_search_basic_fields {
  background-color: transparent;
  border: none;
  padding: 0;
}
#wd_printable_content .wd_search_basic_fields select[name="year"],
#wd_printable_content label[for="item_basic_search_43_year"] {
  display: none;
}
.wd_search_advanced_link {
  display: none;
}
#wd_printable_content form {
  padding-bottom: 60px;
  position: relative;
  width: 100%;
}
#wd_printable_content form .wd_search_basic_fields {
  width: 100%;
}
#wd_printable_content form:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 5rem;
  height: 0.1875rem;
  background: linear-gradient(to left, #ffcd03 0%, #ffe19b 100%);
}
#wd_printable_content input[type="text"] {
  border-radius: 2.125rem;
  padding: 20px 15px;
  border: 1px solid #3f4443;
  background-color: #faf9f8;
  max-width: 50% !important;
  width: 100%;
}
#wd_printable_content input[type="text"]::placeholder {
  opacity: 1;
  color: #3f4443;
}
#wd_printable_content button[type="submit"],
p.more a {
  height: 42px;
  border: none;
  border-radius: 2.125rem;
  background-color: #3f4443;
  color: #fff;
  transition: all 0.3s;
}
#wd_printable_content .wd_events_more {
  height: 42px;
  border: none;
  border-radius: 2.125rem;
  background-color: #3f4443;
  color: #fff;
  transition: all 0.3s;
}
#wd_printable_content button[type="submit"]:hover,
#wd_printable_content .wd_events_more:hover {
  background-color: #e87722;
  border-color: #e87722;
  padding: 0 1.875rem;
}
.wd_pageid_45 p.more a {
  border: 2px solid #3f4443;
  border-radius: 2.125rem;
  padding: 0.625rem 15px;
  font-weight: 600;
  text-decoration: none;
  color: #3f4443;
  background: transparent;
  transition: all 0.3s;
  display: inline-block;
  margin-bottom: 10px;
  line-height: 1.063rem;
}
.wd_pageid_45 p.more a:hover {
  border-color: #ffc72c;
  color: #000;
  border-width: 2px;
  padding: 0.625rem 2.5rem;
  background: linear-gradient(180deg, #ffcd03 0%, #ff9e1b 100%);
}
p.more a {
  padding: 10px 15px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
}

p.more a:hover {
  padding: 10px 1.875rem;
  text-decoration: none;
  background-color: #e87722;
  border-color: #e87722;
}
#wd_printable_content .wd_search_basic_fields button[type="submit"] {
  margin-left: 10px;
}
/* END SEARCH FORM */

/* PAGE MENU */
.wd_page_menu .wd_linkmenu-header {
  border-radius: 2.125rem;
  padding: 7px 10px;
  border-color: #3f4443;
}
.wd_page_menu .wd_page_links {
  border: none;
}

.wd_page_menu .wd_page_links li.wd_page_link.wd_page_active {
  border: 1px solid #c15401;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.wd_page_menu .wd_page_links li.wd_page_link {
  margin: 0 5px 5px 0;
}
.wd_page_menu .wd_page_links li.wd_page_link.wd_page_mid1,
.wd_page_menu .wd_page_links li.wd_page_link.wd_page_mid2,
.wd_page_menu .wd_page_links li.wd_page_link.wd_page_end {
  padding: 0 5px 5px 0;
}
.wd_page_menu .wd_page_links li.wd_page_link a:hover {
  background-color: #faf9f8;
}
.wd_page_menu .wd_page_links li.wd_page_link a {
  text-decoration: none;
  color: #3f4443;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.wd_page_menu .wd_page_links li.wd_page_link:not(.wd_page_active) {
  border-color: transparent;
}
.wd_page_menu .wd_page_links li.wd_page_prev span {
  display: inline-block;
  border-radius: 2.125rem;
  padding: 8px 20px;
  border: 2px solid #8c8f8e;
  background-color: transparent;
  color: #8c8f8e;
  font-weight: 600;
}
.wd_page_menu .wd_page_links li.wd_page_prev span:hover {
  background-color: transparent;
}
.wd_page_menu .wd_page_links li.wd_page_prev a,
.wd_page_menu .wd_page_links li.wd_page_next a {
  display: inline;
  border-radius: 2.125rem;
  padding: 8px 20px;
  border: 2px solid #3f4443;
  background-color: transparent;
  color: #3f4443;
  font-weight: 600;
  transition: all 0.3s;
}
.wd_page_menu .wd_page_links li.wd_page_prev a:hover,
.wd_page_menu .wd_page_links li.wd_page_next a:hover {
  background-color: transparent;
  border-color: #ffc72c;
  color: #000;
  padding: 0.5rem 2.5rem;
  background: linear-gradient(180deg, #ffcd03 0%, #ff9e1b 100%);
}
/* END PAGE MENU */

/* START tagline */
.wd_tagline p {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 40px;
  position: relative;
  margin-bottom: 100px;
  max-width: 500px;
  font-size: 1.5rem;
}
.wd_tagline p:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 5rem;
  height: 0.1875rem;
  background: linear-gradient(to left, #ffcd03 0%, #ffe19b 100%);
}
/* END tagline */

/* START POLICIES AND GUIDELINES, 
START BOARD COMMITTEES */
.wd_pageid_126 .wd_category_14 .item {
  margin: 20px 0;
}
.wd_pageid_126 .wd_category_8_9-wrapper .category_header {
  display: none;
}
.wd_pageid_126 .wd_category_8_9-wrapper {
  margin-top: 60px;
}
.wd_pageid_117 .wd_category_10_11-wrapper .category_intro .category_summary p,
.wd_pageid_126 .wd_category_8_9-wrapper .category_intro .category_summary p {
  color: #c15401;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
}
.wd_pageid_117 .wd_category_items_10 .item,
.wd_pageid_126 .wd_category_items_8 .item {
  margin-top: 0;
  padding-top: 0;
}
.wd_pageid_117 .wd_category_items_10 .item .item_name,
.wd_pageid_126 .wd_category_items_8 .item .item_name {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.wd_pageid_117 .wd_category_items_11 .item,
.wd_pageid_126 .wd_category_items_9 .item {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 60px;
  border-bottom: 1px solid #3f4443;
  padding-bottom: 40px;
}
.wd_pageid_117 .wd_category_items_11 .item p,
.wd_pageid_126 .wd_category_items_9 .item p {
  padding-left: 40px;
}
.wd_pageid_117 .wd_category_items_11 .item_name,
.wd_pageid_126 .wd_category_items_9 .item_name {
  font-size: 1.5rem;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 40px;
  position: relative;
}
.wd_pageid_117 .wd_category_items_11 .item_name:hover,
.wd_pageid_126 .wd_category_items_9 .item_name:hover {
  cursor: pointer;
}
/* Downward font awesome chevron */
.wd_pageid_117 .wd_category_items_11 .item_name:after,
.wd_pageid_126 .wd_category_items_9 .item_name:after {
  content: "\f107";
  font-family: "FontAwesome" !important;
  font-size: 2rem;
  position: absolute;
  left: 5px;
  top: 50%;
  line-height: 0;
  transform-origin: center;
  transform: rotate(-90deg) translateY(-50%);
  transition: all 0.3s ease-in-out;
}
.wd_pageid_117 .wd_category_items_11 .item_name.active:after,
.wd_pageid_126 .wd_category_items_9 .item_name.active:after {
  transform: rotate(0deg) translateY(-50%);
}
.wd_pageid_117 .wd_category_items_11 .item a,
.wd_pageid_126 .wd_category_items_9 .item a {
  font-weight: 500;
  color: #c15401;
}
.wd_pageid_117 .wd_category_items_11 .item .wd_attachment,
.wd_pageid_126 .wd_category_items_9 .item .wd_attachment {
  padding-left: 40px;
}
.wd_pageid_117 .wd_category_items_11 .item .wd_attachment_image,
.wd_pageid_126 .wd_category_items_9 .item .wd_attachment_image {
  display: none;
}
.wd_pageid_117 .wd_category_items_11 .item .wd_attachment p,
.wd_pageid_126 .wd_category_items_9 .item .wd_attachment p {
  padding-left: 0;
}
.wd_pageid_117 .wd_category_items_11 .item .wd_attachment .wd_attachment_size,
.wd_pageid_126 .wd_category_items_9 .item .wd_attachment .wd_attachment_size {
  display: none;
}

table.responsive {
  table-layout: fixed;
  width: 100%;
  border: 1px solid #d9dad9;
  border-radius: 10px 10px 0 0;
  border-spacing: 0;
  overflow: hidden;
}
table.responsive thead th {
  text-align: center;
  background-color: #3f4443;
  color: #fff;
}
table.responsive td,
table.responsive th {
  padding: 16px;
  position: relative;
}
table.responsive td::before,
table.responsive th::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 0;
  width: 1px;
}
table.responsive td::before {
  background-color: #d9dad9;
}
table.responsive th::before {
  background-color: #fff;
}
table.responsive td:last-child::before,
table.responsive th:last-child::before {
  display: none;
}
table.responsive tbody tr:nth-child(even) td {
  background-color: #faf9f8;
}
table.responsive tbody td {
  text-align: center;
}
table.responsive tbody tr td:first-of-type {
  text-align: left;
}
/* END POLICIES AND GUIDELINES,
END BOARD COMMITTEES */

/* START IR FILINGS */
.wd_ir_secfilings .wd_selector {
  margin-right: 15px;
}
.wd_ir_secfilings .wd_selector:first-child {
  margin-right: 0;
}
.wd_ir_secfilings .wd_selector .wd_label {
  margin-right: 10px;
}
.wd_sec_filings thead {
  border-radius: 15px 15px 0 0;
}
.wd_sec_filings thead th {
  color: #fff;
  font-weight: 400;
  border-right: 1px solid #fff;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #3f4443;
}
.wd_sec_filings thead th:first-child {
  border-radius: 15px 0 0 0;
}
.wd_sec_filings thead th:last-child {
  border-right: none;
  border-radius: 0 15px 0 0;
}
.wd_sec_filings tbody td {
  padding: 20px 10px;
  border-right: 1px solid #dddddd;
  vertical-align: initial;
}
.wd_sec_filings tbody td:first-child {
  border-left: 1px solid #dddddd;
}
.wd_sec_filings tr:nth-child(even) {
  background: #faf9f8;
}
.wd_ir_secfilings div.wd_filer_label {
  display: none;
  text-align: left !important;
  margin-bottom: 10px;
}
.wd_ir_secfilings .wd_selectors {
  margin-bottom: 20px !important;
}
.wd_ir_secfilings .wd_selectors .wd_selector {
  float: left !important;
  margin-left: 30px;
}
.wd_ir_secfilings .wd_selectors .wd_selector .wd_label {
  display: none;
}
.wd_ir_secfilings .wd_selectors .wd_selector:first-child {
  margin-left: 0 !important;
}
.wd_ir_secfilings .wd_selectors .wd_linkmenu-label {
  width: auto !important;
}
.wd_ir_secfilings .wd_linkmenu-header {
  border-radius: 2.125rem;
  padding: 7px 10px;
  border-color: #3f4443;
  border-width: 2px;
  font-weight: 500;
  color: #3f4443;
}
.wd_ir_secfilings .wd_selector .wd_linkmenu-icon.fa-chevron-down::before {
  content: "\f0d7" !important;
}
.wd_sec_filings .wd_document_format a {
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s;
  display: block;
  width: 100%;
  padding-bottom: 10px;
  color: #c15401;
}
.wd_sec_filings .wd_document_format a:last-child {
  padding-bottom: 0;
}
.wd_sec_filings .wd_document_format a:hover {
  text-decoration: underline;
}
.wd_sec_filings tfoot a {
  color: #c15401;
}
/* END IR FILINGS */

/* START ANNUAL REPORTS */
.wd_pageid_64 .wd_standard,
.wd_pageid_120 .wd_standard {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.wd_pageid_64 .wd_standard .wd_category,
.wd_pageid_120 .wd_standard .wd_category {
  width: calc((100% - 90px) / 4);
}
.wd_pageid_64 .wd_standard .wd_category h1,
.wd_pageid_120 .wd_standard .wd_category h1 {
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px solid #3f4443;
}
.wd_pageid_120 .item_name,
.wd_pageid_120 table .wd_attachment_image,
.wd_pageid_120 table .wd_attachment_size,
.wd_pageid_120 .wd_attachment_spacer {
  display: none;
}
.wd_pageid_120 .wd_attachment_title {
  position: relative;
}
/* font awesome filled circle */
.wd_pageid_120 .wd_attachment_title:before {
  display: inline-block;
  content: "\f111";
  font-family: "FontAwesome";
  margin-right: 7px;
  font-size: 8px;
  vertical-align: middle;
}
.wd_pageid_120 .item {
  padding: 0 0 0 0px;
  margin: 0;
}
.wd_pageid_120 .item ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.wd_pageid_120 .item ul li {
  font-weight: 500;
  line-height: 1.3;
  font-size: 1.063rem;
  margin-bottom: 17px;
}
.wd_pageid_120 .item ul li a {
  text-decoration: none;
  color: #c15401;
}
.wd_pageid_120 .item ul li:before {
  display: inline-block;
  content: "\f111";
  font-family: "FontAwesome";
  margin-right: 7px;
  font-size: 8px;
  vertical-align: middle;
  color: #c15401;
}
.wd_pageid_120 .itemlink {
  font-weight: 500;
  text-decoration: none;
  color: #c15401;
}
.wd_pageid_120 .wd_standard div.wd_toolbar {
  flex-basis: 100%;
}
.wd_pageid_64 .wd_toolbar {
  width: 100%;
}

.wd_category_header {
  margin: 10px 0;
  overflow: auto;
}
.wd_item_list {
  margin: 10px 0;
  overflow: auto;
}
.wd_item {
  padding: 0;
  margin: 0 0 10px 0;
}

/* END ANNUAL REPORTS */

/* START QUARTERLY RESULTS */

.wd_pageid_64 #wd_printable_content form.wd_category-select:after {
  display: none;
}
.wd_pageid_64 #wd_printable_content form.wd_category-select {
  padding-bottom: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  width: auto;
}
.wd_pageid_64 #wd_printable_content form.wd_category-select select {
  width: auto;
  border-radius: 2.125rem;
  border-color: #3f4443;
  border-width: 2px;
  font-weight: 500;
  color: #3f4443;
  height: 42px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* background-image: url("../images/caret-down.svg"); */
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: right 10px center;
  padding-right: 30px;
}
.wd_pageid_64 .wd_category h1 {
  display: none;
}
.wd_pageid_64 .wd_standard .wd_category {
  width: 100%;
}
.wd_pageid_64 .wd_category_items {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.wd_pageid_64 .wd_standard .wd_category .item_name {
  display: block;
  font-size: 2rem;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px solid #3f4443;
  margin: 0 0 0.67em 0;
}
.wd_pageid_64 .wd_category_items .item {
  width: calc((100% - 90px) / 4);
  margin-top: 0;
  padding-top: 0;
}
.wd_pageid_64 .item ul {
  margin-bottom: 0;
  /*	padding-left: 0;*/
}
.wd_pageid_64 .item ul li {
  list-style-type: none;
  margin-bottom: 10px;
}
.wd_pageid_64 .wd_standard .wd_category .wd_attachment_image,
.wd_pageid_64 .wd_standard .wd_category .wd_attachment_size,
.wd_pageid_64 .wd_attachment_spacer {
  display: none;
}
.wd_pageid_64 .wd_attachment {
  padding-left: 0;
}
.wd_pageid_64 .wd_attachment p {
  margin-bottom: 10px !important;
}
.wd_pageid_64 .itemlink,
.wd_pageid_64 .item a {
  font-weight: 500;
  text-decoration: none;
  color: #c15401;
}
.wd_pageid_64 .item a:before {
  display: inline-block;
  content: "\f111";
  font-family: "FontAwesome";
  margin-right: 7px;
  font-size: 8px;
  vertical-align: middle;
}

/* END QUARTERLY RESULTS */

/* Events and Webcasts */
.wd_events_inputs {
  overflow: visible !important;
}
ul.wd_events_tabs {
  display: inline-flex;
  gap: 20px;
  justify-content: center;
  padding-left: 0;
  padding: 0.5625rem;
  border-radius: 3.6875rem;
  box-shadow: 0 2.82px 8.3px 0 rgba(0, 0, 0, 0.05);
  background-color: #faf9f8;
  list-style: none;
  margin: 0;
}
li.wd_events_tab .fa.fa-arrow-circle-right,
li.wd_events_tab .fa.fa-arrow-circle-left {
  display: none;
}
.wd_events_inputs .wd_events_tabs .wd_events_tab.active {
  color: #fff;
  background-color: #3f4443;
  border-radius: 2.125rem;
  padding: 1.375rem 2.8125rem;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  background-image: none;
}
.wd_events_inputs .wd_events_tabs .wd_events_tab {
  color: #3f4443;
  background-color: transparent;
  border-radius: 2.125rem;
  padding: 1.375rem 2.8125rem;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  border: none;
}
.wd_modal-outer {
  padding: 50px !important;
  border-radius: 10px;
}
.wd_modal-outer .wd_modal-close.fa {
  position: absolute;
  top: 20px;
  right: 20px;
  color: rgb(63, 68, 67);
}
.wd_modal-outer .add_header,
.wd_modal-outer .subscribe_header {
  color: rgb(63, 68, 67);
  font-weight: 500;
}
#wd_event_modal .wd_form_footer input[value="Cancel"] {
  border: none;
  background-color: transparent;
  color: rgb(63, 68, 67);
  font-weight: 500;
}
.wd_events-detail .wd_attachment_icon {
  display: none;
}
.wd_events-detail .wd_attachment_wrapper {
  margin-left: 0;
}
.wd_events_month_events .wd_attachment_title {
  color: #3f4443;
  text-decoration: none;
}
.wd_events_month_events .wd_attachment_title a {
  color: #3f4443;
  text-decoration: none;
  font-weight: 500;
}
.wd_events_month_events .wd_attachment_title svg {
  margin-left: 8px;
}
.wd_events-detail .wd_attachment_title a {
  font-weight: 500;
  text-decoration: none;
  color: #3f4443;
}
.wd_events-detail .wd_attachment_title a svg {
  margin-left: 8px;
}

/* Breadcrumb */
.breadcrumb {
  color: #c15401;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}
.breadcrumb a {
  color: #3f4443;
  text-decoration: none;
}
.breadcrumb a.nav {
  color: #c15401;
  text-decoration: none;
}
.breadcrumb a.nav:hover {
  text-decoration: underline;
}
.breadcrumb span {
  padding: 0 4px;
}
.breadcrumb-wrapper .wd_investors {
  display: inline;
}
/* End Breadcrumb */

/* SITE SUBNAV */
.wd_subnav-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  padding-bottom: 35px;
  border-bottom: 1px solid #3f4443;
  margin-bottom: 20px;
}
.wd_subnav-page-menu {
  display: inline-flex;
  gap: 20px;
  justify-content: center;
  padding-left: 0;
  padding: 0.5625rem;
  border-radius: 3.6875rem;
  box-shadow: 0 2.82px 8.3px 0 rgba(0, 0, 0, 0.05);
  background-color: #faf9f8;
  list-style: none;
  margin: 0;
}
.wd_subnav-page-menu li {
}
.wd_subnav-page-menu li a {
  color: #3f4443;
  background-color: transparent;
  border-radius: 2.125rem;
  padding: 1.375rem 2.8125rem;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
}
.wd_subnav-page-menu li.on a {
  color: #fff;
  background-color: #3f4443;
  border-radius: 2.125rem;
  padding: 1.375rem 2.8125rem;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
}
/* END SITE SUBNAV */

/* START TOOLBAR */
div.wd_toolbar {
  border: none;
  margin-top: 0;
  padding: 0;
}
div.wd_toolbar ul.wd_tools li.wd_tool {
  float: right;
}
.wd_newsfeed_releases-detail .wd_toolbar {
  margin-bottom: 0px;
}
.wd_newsfeed_releases .wd_toolbar {
  display: none;
}
span.wd_tool_icon > a:link {
  border-radius: 50%;
  background-color: #e87722 !important;
}

/* END TOOLBAR */

/* Events */
.wd_events_inputs > select,
.wd_events_inputs > button {
  display: none;
}
ul.wd_events_tabs {
  float: none !important; /* Override inline style */
}
.wd_date_time_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.9375rem;
}
.wd_events-detail .wd_date_time_container .item_date,
.wd_events-detail .wd_date_time_container .item_time {
  margin-bottom: 0;
}
.wd_events .item,
.wd_events_past_summary .item {
  display: flex;
  gap: 60px;
}
.wd_events_list .wd_events_month_header,
.wd_events_header_container {
  display: none;
}
.wd_events .item .event_container-left,
.wd_events_past_summary .item .wd_event_sidebar {
  width: calc(75% - 30px);
}
.wd_events_past_summary .item .wd_event_sidebar {
  margin-right: 0 !important;
  float: none !important;
}
.wd_events .item .event_container-right,
.wd_events_past_summary .item .wd_event_info {
  width: calc(25% - 30px);
  display: flex;
  flex-direction: column;
  /*gap: 10px;*/
}
.wd_event_sidebar_group {
  margin: 0;
}
.wd_events .item .event_container-right .fa {
  display: none;
}
.wd_events .item .event_container-right a {
  color: #3f4443;
  text-decoration: none;
  font-weight: 500;
}
.wd_events .item .event_container-right a svg {
  margin-left: 8px;
}
.wd_date_time_container .wd_separator {
  margin: 0 10px;
  position: relative;
  top: -8px;
}
.wd_date_time_container .item_date {
  margin-bottom: 0;
}
.wd_date_time_container .item_date .fa,
.wd_events_past_summary .item_date .fa {
  display: none;
}
.wd_date_time_container .item_time {
  color: #c15401;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.9375rem;
}
.wd_events_past_summary {
  display: none !important;
}
.wd_events_list .item,
.wd_events_past_summary .item {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid RGB(140, 143, 142);
}
.wd_events_list .wd_event {
  background: none !important;
}
.wd_events_list .wd_title a,
.wd_events_past_summary .wd_title a {
  color: #3f4443;
  line-height: 1.3;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
}
.wd_events_list .wd_attachment_container {
}
.wd_events_list .wd_attachment_container .wd_attachment_wrapper {
  margin-left: 0;
}
.wd_events_list .wd_attachment_icon,
.wd_attachment_container .wd_attachment_description {
  display: none;
}
.wd_date_time_container .item_time {
  margin-bottom: 0;
}
.wd_date_time_container .item_time .fa {
  display: none;
}
.wd_events-detail .wd_event {
  display: flex;
  flex-direction: row-reverse;
  gap: 40px;
  flex-wrap: wrap;
}
.wd_events-detail .wd_event.column-reverse {
  flex-direction: column-reverse;
}
.wd_events-detail .wd_event_info {
  width: calc(55% - 20px);
}
.wd_events-detail .wd_event_sidebar {
  width: calc(45% - 20px);
}
.wd_events-detail .wd_event_sidebar.full-width,
.wd_events-detail .wd_event_info.full-width {
  width: 100%;
}
.wd_events-detail .wd_event_info .wd_captioned_image {
  width: auto !important;
  float: none !important;
  margin: none !important;
  margin: 0 !important;
}
.wd_events-detail .wd_event_info img {
  width: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.wd_events-detail .wd_event_info .wd_image {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  border-radius: 15px;
}
.wd_events-detail .wd_event_sidebar {
  background-color: #faf9f8;
  border-radius: 15px;
  padding: 35px;
  margin-right: 0;
}
.wd_events-detail .wd_event_sidebar_links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0;
}
.wd_events-detail .wd_event_sidebar_item {
}
.wd_events-detail .wd_event_sidebar_item .fa {
  display: none;
}
.wd_events-detail .wd_event_sidebar_item a {
  color: #3f4443;
  text-decoration: none;
  font-weight: 500;
}
.wd_events-detail .wd_event_sidebar_item a svg {
  margin-left: 8px;
}
.wd_events-detail .wd_toolbar {
  margin-top: 15px;
}
.wd_events-detail .wd_title {
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  color: rgb(63, 68, 67);
  font-weight: 500;
}
/* End Events Page */

/* FAQ */
.wd_dropdown {
  display: none;
}
.wd_pageid_118 .item .item_name {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.wd_pageid_118 .item .item_name:after {
  content: "+";
  display: block;
}
.wd_pageid_118 .item.wd_open .item_name:after {
  content: "-";
}

/* Client Overrides */
#utility-nav li:last-child a:after {
  display: none;
}
/* End Client Overrides */

/* YouTube Responsive Video Containers */
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* END YouTube Responsive Video Containers */

div.wd_rssfeeds_item {
  margin-bottom: 10px;
}

/* Extra large devices (large desktops) */
@media (max-width: 1199px) {
  .wd_page_menu .wd_page_limit {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .wd_page_menu nav {
    text-align: center;
    width: 100%;
  }
  .wd_page_menu nav .wd_page_links {
    float: none;
  }
}
@media (max-width: 1024px) {
}

/* Large devices (desktops) */
@media (max-width: 992px) {
  .wd_featureboxes_financial_tabbed .wd_tabs {
    gap: 5px;
  }
  .wd_subnav-page-menu li a.on,
  .wd_featureboxes_financial_tabbed .wd_tab.wd_tab-active,
  .wd_events_inputs .wd_events_tabs .wd_events_tab.active {
    color: #fff;
    background-color: #3f4443;
    border-radius: 2.125rem;
    padding: 4px 15px;
    display: inline-block;
    font-weight: 500;
    text-decoration: none;
    font-size: 0.85rem;
    white-space: pre-wrap;
  }
  .wd_subnav-page-menu li a,
  .wd_featureboxes_financial_tabbed .wd_tab,
  .wd_events_inputs .wd_events_tabs .wd_events_tab {
    padding: 4px 15px;
    font-size: 0.85rem;
    text-align: center;
    white-space: pre-wrap;
  }
  #wd_featurebox-teaser_6 .wd_standard-teaser .item,
  #wd_featurebox-teaser_5 .wd_standard-teaser .item {
    width: calc((100% - 30px) / 2);
  }

  .wd_pageid_20 .wd_category_items .item {
    flex-basis: calc(50% - 10px);
  }
  .wd_pageid_120 .wd_standard .wd_category {
    width: calc((100% - 30px) / 2);
  }
  .wd_pageid_64 .wd_category_items .item {
    width: calc((100% - 30px) / 2);
  }
  #ir_featurebox_3-filings .wd_thead {
    display: none !important;
  }
  #ir_featurebox_3-filings .wd_tr {
    flex-direction: column;
    margin-bottom: 40px;
  }
  #ir_featurebox_3-filings .mobile-header {
    display: inline;
    font-weight: 500;
  }
  #ir_featurebox_3-filings .wd_tr .wd_td {
    width: 100%;
  }
  #ir_featurebox_3-filings .wd_tr .wd_td.wd_filings-form {
    background-color: #3f4443;
    color: #fff;
  }
  div.wd_featureboxes_middle {
    padding: 100px 0px;
  }
  /* Events */
  .wd_events .item,
  .wd_events_past_summary .item {
    flex-wrap: wrap;
    gap: 20px;
  }
  .wd_events .item .event_container-left,
  .wd_events_past_summary .item .wd_event_sidebar,
  .wd_events .item .event_container-right,
  .wd_events_past_summary .item .wd_event_info {
    width: 100%;
  }
  .wd_events-detail .wd_event {
    flex-direction: column;
    gap: 20px;
  }
  .wd_events-detail .wd_event_sidebar,
  .wd_events-detail .wd_event_info {
    width: 100%;
  }

  table.responsive {
    border: none;
  }
  table.responsive tr {
    border: 1px solid #d9dad9;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    margin-bottom: 30px;
  }
  table.responsive tbody td {
    text-align: left;
  }
  table.responsive tbody tr td:first-child {
    font-weight: 700;
    background-color: #3f4443;
    color: #fff;
  }
  table.responsive td::before,
  table.responsive th::before,
  table.responsive tbody td.wd_empty_cell {
    display: none;
  }
}

/* Medium devices (tablets) */
@media (max-width: 767px) {
  .bread-toolbar-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
  }
  .wd_newsfeed_releases-detail .wd_layout-masonry {
    width: 100% !important;
    padding-left: 0 !important;
  }
  .wd_pageid_117 .wd_category_10,
  .wd_pageid_117 .wd_category_11 {
    width: 100%;
  }
  #wd_printable_content form .wd_search_basic_fields {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  #wd_printable_content form .wd_search_basic_fields input[type="text"],
  #wd_printable_content form .wd_search_basic_fields .wd_search_button {
    max-width: 100% !important;
    margin-left: 0;
  }
  .wd_pageid_120 .wd_standard .wd_category {
    width: 100%;
  }
  .wd_pageid_20 .wd_category_items .item {
    flex-basis: 100%;
  }

  /* Stock Information FB */
  .stock_featurebox_wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .stock_featurebox_wrapper .stock-information {
    flex-direction: column;
  }
  .stock_featurebox_wrapper .stock-information .stock-ticker-wrapper,
  .stock_featurebox_wrapper .stock-information .stock-ticker-price,
  .stock_featurebox_wrapper .stock-information .stock-ticker-change {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #faf9f8;
    padding-top: 20px;
  }
  .stock_featurebox_wrapper .stock-information .stock-ticker-change {
    border-bottom: none;
  }
  /* End Stock Information FB */

  /* Start Quarterly FB */
  div.wd_featureboxes_quarterly .wd_featurebox ul li {
    width: calc(100% / 2);
    margin-bottom: 40px;
  }
  /* End Quarterly FB */

  /* SUBSCRIBE FEATUREBOX */
  div.wd_featureboxes_subscribe {
    padding: 50px 1.25rem;
  }
  #subscribe_form_2 .wd_form_field {
    margin: 0;
    width: 100%;
  }
  /* END SUBSCRIBE FEATUREBOX */

  .wd_pageid_64 .wd_category_items .item {
    width: 100%;
  }

  .stock_featurebox_wrapper .stock-information {
    padding: 0;
  }

  /* START MIDDLE ALTERNATING FEATUREBOXES */
  .wd_featureboxes_middle_alternating .wd_featurebox {
    flex-direction: column;
  }
  .wd_featureboxes_middle_alternating
    .wd_featurebox_container:nth-child(2n)
    .wd_featurebox {
    flex-direction: column;
  }
  .wd_featureboxes_middle_alternating .wd_featurebox_image {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 78%;
  }
  .wd_featureboxes_middle_alternating .wd_featurebox_content {
    width: 100%;
    padding-left: 0;
  }
  .wd_featureboxes_middle_alternating
    .wd_featurebox_container:nth-child(2n)
    .wd_featurebox_content {
    padding-right: 0;
  }

  /* END MIDDLE ALTERNATING FEATUREBOXES */

  /* Event Modals */
  .wd_modal-outer {
    padding: 50px 20px !important;
    border-radius: 10px;
  }
  /* End Event Modals */
}

/* Small devices (landscape phones) */
@media (max-width: 575px) {
  /* Start Quarterly FB */
  div.wd_featureboxes_quarterly .wd_featurebox ul li {
    width: calc(100%);
    margin-bottom: 40px;
  }
  #wd_featurebox-teaser_6 .wd_standard-teaser .item,
  #wd_featurebox-teaser_5 .wd_standard-teaser .item {
    width: 100%;
  }
  /* End Quarterly FB */

  .wd_sec_filings .wd_document span {
    display: block;
    white-space: break-spaces;
  }
}
@media (max-width: 410px) {
  .wd_ir_secfilings .wd_selectors .wd_selector {
    margin-left: 0;
  }
  .wd_ir_secfilings .wd_selectors {
    display: flex;
    flex-direction: column;
  }
}

/* CLIENT CSS OVERRIDES */
hr.wd_form_placeholder {
  margin: 0;
  border-color: #3f4443;
}

footer#colophon #colophon-columns .colophon-group nav ul > li > span.nolink {
  color: #c15401;
}
#utility-nav li a {
  padding-right: 7px;
  position: relative;
}
#utility-nav li a.parent-link:after {
  width: 9px;
  height: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  content: "";
  margin-left: 0.375rem;
  background-image: url("data:image/svg+xml,%3Csvg width=%279%27 height=%276%27 viewBox=%270 0 9 6%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg id=%27Navigation Arrow%27%3E%3Cpath id=%27Polygon 2%27 d=%27M4.90296 5.33616C4.68703 5.55461 4.31297 5.55461 4.09704 5.33616L0.142199 1.33498C-0.17501 1.01406 0.0730712 0.500001 0.545157 0.500001L8.45484 0.5C8.92693 0.5 9.17501 1.01406 8.8578 1.33498L4.90296 5.33616Z%27 fill=%27%23E87722%27/%3E%3C/g%3E%3C/svg%3E%0A");
  position: absolute;
  top: 48%;
  right: -17%;
  transform: translate(-50%, -50%);
  padding-right: 6px;
}
#utility-nav > li {
  position: relative;
}
#utility-nav > li a {
  text-transform: none;
}
#masthead-navigation-primary #utility-nav .dropdown {
  margin-top: 0 !important;
}
#utility-nav .parent-link-container:hover ul {
  visibility: visible;
  opacity: 1;
}
#utility-nav .dropown > li > a.has-dropdown,
.wd_nav .dropdown > li > a.has-dropdown {
  position: relative;
}
#utility-nav .dropdown > li > a.has-dropdown:after,
.wd_nav .dropdown > li > a.has-dropdown:after {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  content: "";
  margin-left: 0.375rem;
  background-image: url("data:image/svg+xml,%3Csvg width=%279%27 height=%276%27 viewBox=%270 0 9 6%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg id=%27Navigation Arrow%27%3E%3Cpath id=%27Polygon 2%27 d=%27M4.90296 5.33616C4.68703 5.55461 4.31297 5.55461 4.09704 5.33616L0.142199 1.33498C-0.17501 1.01406 0.0730712 0.500001 0.545157 0.500001L8.45484 0.5C8.92693 0.5 9.17501 1.01406 8.8578 1.33498L4.90296 5.33616Z%27 fill=%27%23E87722%27/%3E%3C/g%3E%3C/svg%3E%0A");
  position: relative;
  transform: translate(-50%, -50%);
  margin-left: 10px;
  width: 10px;
  height: 6px;
  margin-top: 11px;
  position: relative;
  top: 2px;
}
#utility-nav .dropdown .wd_subnav,
.wd_nav .dropdown .wd_subnav {
  list-style: none;
}
.wd_nav {
  border-bottom: 1px solid #8c8f8e;
}
.wd_nav {
  display: none;
}
@media only screen and (min-width: 1280px) {
  #masthead-navigation-primary #primary-nav > li.wd_nav {
    display: none;
  }
}
@media (max-width: 1279px) {
  #utility-nav .parent-link-container {
    display: none;
  }
  #masthead-navigation-primary #primary-nav > li.wd_nav {
    display: block;
  }
}
.wd_pageid_117 .wd_category_12 {
  max-width: 500px;
  margin-bottom: 100px;
}
.wd_pageid_117 .category_header {
  display: none;
}
.wd_pageid_117 .wd_category_12 .category_intro .category_summary {
  position: relative;
  padding-bottom: 60px;
}
.wd_pageid_117 .wd_category_12 .category_intro .category_summary:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 5rem;
  height: 0.1875rem;
  background: linear-gradient(to left, #ffcd03 0%, #ffe19b 100%);
}
.wd_pageid_117 .wd_category_10_11-wrapper,
.wd_pageid_126 .wd_category_8_9-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
}
.wd_pageid_117 .wd_category_10,
.wd_pageid_126 .wd_category_8 {
  width: calc(40% - 40px);
}
.wd_pageid_117 .wd_category_11,
.wd_pageid_126 .wd_category_9 {
  width: calc(60% - 40px);
}

@media (max-width: 1024px) {
  .wd_pageid_117
    .wd_category_10_11-wrapper
    .wd_pageid_126
    .wd_category_8_9-wrapper {
    gap: 40px;
  }
  .wd_pageid_117 .wd_category_10,
  .wd_pageid_126 .wd_category_8,
  .wd_pageid_126 .wd_category_9 {
    width: 100%;
  }
  .wd_pageid_117 .wd_category_11,
  .wd_pageid_126 .wd_category_9 {
    width: 100%;
  }
}
.wd_pageid_126 .wd_category_14 .category_header {
  display: none;
}
.wd_pageid_126 .wd_category_14 table {
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  border-spacing: 0;
}
.wd_pageid_126 .wd_category_14 th {
  background-color: #3f4443;
  color: #fff;
  /*    text-align: left;*/
  position: relative;
  padding: 6px 15px;
  font-weight: 500;
}
.wd_pageid_126 .wd_category_14 th:first-child {
  border-radius: 15px 0 0 0;
}
.wd_pageid_126 .wd_category_14 th:last-child {
  border-radius: 0 15px 0 0;
}
.wd_pageid_126 .wd_category_14 th:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 0;
  width: 1px;
  height: calc(100% - 10px);
  background-color: #fff;
}
.wd_pageid_126 .wd_category_14 th:last-child:after {
  display: none;
}
/*.wd_pageid_126 .wd_category_14 td {
    padding: 8px 15px;
   
}*/
.wd_pageid_126 .wd_category_14 td:first-child a {
  color: #e87722;
  font-weight: 500;
  text-decoration: underline;
}
.wd_pageid_126 .wd_top_border > td {
  border-top: 1px solid #e0e0e0;
}
.wd_pageid_126 td span.wd_mobile_committee_header {
  display: none;
}
.wd_pageid_126 td[data-th="Name"] span.wd_mobile_committee_header {
  display: none;
}
.wd_pageid_126 tr.grey-background {
  background-color: #faf9f8;
}

@media (max-width: 992px) {
  .wd_pageid_126 .wd_category_14 table {
    border-left: none;
    border-right: none;
  }
  .wd_pageid_126 table thead {
    display: none;
  }
  .wd_pageid_126 table tbody tr {
    display: flex;
    flex-direction: column;
  }
  .wd_pageid_126 .wd_top_border:first-child {
    margin-top: 0px;
  }
  .wd_pageid_126 .wd_top_border {
    margin-top: 60px;
  }
  .wd_pageid_126 .wd_top_border td > span {
    display: inline;
    font-weight: 600;
  }
  .wd_pageid_126 td span.wd_mobile_committee_header {
    display: inline;
    font-weight: 600;
  }
  .wd_pageid_126 tr td:nth-child(odd) {
  }
  .wd_pageid_126 tr td[data-th="Committee"] {
    border-top: 1px solid #e0e0e0;
  }
  .wd_pageid_126 .wd_top_border td:first-child {
    background-color: #3f4443;
    color: #fff;
    font-weight: 600;
  }
  .wd_pageid_126 .wd_top_border td:first-child span {
    display: none;
  }
  .wd_pageid_126 .wd_top_border > td {
    border-top: none;
  }
  .wd_pageid_126 table tr td {
    border-left: none;
    border-right: none;
  }
}

.wd_item_search_form_container .wd_search_form_basic {
  display: inline-block;
}
.wd_search_basic_fields {
  display: inline-block;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  padding: 3px;
  vertical-align: middle;
}
.wd_search_basic_fields > * {
  vertical-align: middle;
}
.wd_item_search_form_container .wd_input_datepicker {
  margin-right: 2px;
}
.wd_search_button {
  color: #999999;
}
.wd_search_button.wd_highlight {
  color: #000000;
}
.wd_search_advanced_link,
.wd_search_titles_only,
.wd_checkbox {
  white-space: nowrap;
}

.wd_featurebox_icon {
  display: inline;
}
.wd_featurebox_icon img {
  vertical-align: middle;
}
div.wd_featurebox_group div.wd_tabs_wrapper {
  border: none;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 0 5px 0;
}
div.wd_featurebox_group div.wd_featurebox {
  border: none;
  padding: 8px 0 0 0;
}
div.wd_featurebox_group li.wd_tab {
  float: none;
  color: #666666;
}
div.wd_featurebox_group li.wd_tab-active {
  border: none;
  background-color: transparent;
  color: #000000;
  font-weight: bold;
}
div.wd_featurebox_group li.wd_tab-inactive {
  cursor: auto;
}

div.wd_category {
  margin: 0px;
  padding: 0px;
  border: none;
}
div.wd_category_info {
  margin: 0px;
  padding: 0px;
  border: none;
}
div.wd_category_items {
  margin: 0px;
  padding: 0px;
  border: none;
}
.category_intro {
  overflow: auto;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

ul.categories {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  overflow: auto;
}

ul.categories li {
  margin: 0px;
  padding: 0px;
  border: 0px;
}

div.categories_text {
  margin: 0px;
  padding: 0px;
  border: 0px;
}

ul.categories-horizontal.categories-no_thumbnails.categories-no_descriptions
  li {
  float: left;
  margin-left: 1ex;
}
ul.categories-horizontal.categories-no_thumbnails.categories-no_descriptions
  li:first-child {
  margin-left: 0;
}

ul.categories-horizontal.categories-no_thumbnails.categories-no_descriptions
  li
  div.category_name:before {
  content: "| ";
}

ul.categories-horizontal.categories-no_thumbnails.categories-no_descriptions
  li:first-child
  div.category_name:before {
  content: none;
}

ul.categories-horizontal.categories-thumbnails li,
ul.categories-horizontal.categories-descriptions li {
  width: 120px;
  height: 150px;
  float: left;
  margin: 5px;
  padding: 5px;
  border: 0px;
  text-align: center;
  overflow: auto;
}

ul.categories-vertical li {
  margin-top: 5px;
  overflow: auto;
}

ul.categories-vertical.categories-thumbnails div.category_thumbnail {
  float: left;
  margin: 0 5px 0 0;
}

div.wd_toolbar {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin: 15px 0px 15px 0px;
  padding: 5px;
  line-height: 1;
}
div.wd_toolbar-page {
  clear: both;
}
div.wd_toolbar ul.wd_tools {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 5px 0;
}
div.wd_toolbar ul.wd_tools li.wd_tool {
  display: block;
  list-style-type: none;
  margin-right: 5px;
  padding: 0;
  float: right;
}
span.wd_tool_icon > a:link,
span.wd_tool_icon > a:visited {
  border-radius: 2px;
  display: inline-block;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  margin: 0;
  padding: 7px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  background-color: #767676;
}
span.wd_tool_icon > a:hover {
  color: #ffffff;
  background-color: #666666;
  text-decoration: none;
}
div.wd_toolbar ul.wd_tools li.wd_tool-addthis {
  float: left;
}

ul.wd_documents_item_list {
  overflow: auto;
  list-style: none;
}
.wd_documents_item_list > .wd_documents_item {
  overflow: auto;
  padding: 0.5em 0;
}
.wd_documents_item_list > .wd_documents_item + .wd_documents_item {
  border-top: 1px solid #cccccc;
}
.wd_documents_item_list > .wd_documents_item > .wd_icon {
  float: right;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon:visited {
  color: #575959;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon:hover {
  color: #252626;
  background-color: transparent;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-archive:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon-archive:visited {
  color: #6a737b;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-archive:hover {
  color: #3d4347;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-audio:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon-audio:visited {
  color: #b7295a;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-audio:hover {
  color: #851d41;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-image:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon-image:visited {
  color: #b30000;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-image:hover {
  color: #800000;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-pdf:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon-pdf:visited {
  color: #ce1126;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-pdf:hover {
  color: #9c0c1d;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-presentation:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon-presentation:visited {
  color: #dc5034;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-presentation:hover {
  color: #a83e28;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-spreadsheet:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon-spreadsheet:visited {
  color: #7ab800;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-spreadsheet:hover {
  color: #588500;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-video:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon-video:visited {
  color: #71c6c1;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-video:hover {
  color: #549490;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-word:link,
.wd_documents_item_list > .wd_documents_item > a.wd_icon-word:visited {
  color: #0085c3;
}
.wd_documents_item_list > .wd_documents_item > a.wd_icon-word:hover {
  color: #00628f;
}
.wd_documents_item_list > .wd_documents_item > .wd_title {
  font-size: 1.3em;
  font-weight: bold;
}
.wd_documents_item_list > .wd_documents_item > .wd_summary {
  color: #999999;
  margin: 0 2.5em 0 0;
}
.wd_documents_item_list > .wd_documents_item > .wd_summary p {
  margin: 0;
}
ul.wd_layout-simple {
  padding: 0;
  margin: 10px 0;
  list-style: none;
}

.financial-btn {
  background: blue;
}

.financial-btn:hover {
  background-color: #e87722;
  border-color: #e87722;
  padding: 0 1.875rem;
}
