.story-slider-container {
  width: 72%;
  margin: auto;
  margin-top: 40px;
}
.story-slider-container .story-card-container {
  width: 90%;
}
.story-slider-container .story-card-container .card-action {
  width: 100%;
}
.story-slider-container .story-card-container .card-action img {
  width: 90%;
  height: 300px;
  border-radius: 20px;
  -o-object-fit: cover;
     object-fit: cover;
}
.story-slider-container .story-card-container .card-action h1 {
  font-size: 1.4rem;
  line-height: 2.375rem;
  margin: 1rem 0;
}/*# sourceMappingURL=story_slider.css.map */