.story-slider-container {
  //   overflow-x: hidden;
  width: 72%;
  margin: auto;
  margin-top: 40px;

  .story-card-container {
    width: 90%;

    .card-action {
      width: 100%;

      img {
        width: 90%;
        height: 300px;
        border-radius: 20px;
        object-fit: cover;
      }

      h1 {
        font-size: 1.4rem;
        line-height: 2.375rem;
        margin: 1rem 0;
      }
    }
  }
}
