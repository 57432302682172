.accordian-text-updated {
  padding: 0;
  margin: 0;
}

.disc-text {
  list-style: disc;
  padding-left: 23px;
}

.disc-text::before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #000000b4;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 30%;
  transform: translateY(-50%);
}

.bold-text {
  font-weight: 500;
}

a.sublink {
  color: #c95401;
  text-decoration: underline;
}
