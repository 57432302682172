div.wd_toolbar {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin: 15px 0px 15px 0px;
  padding: 5px;
  line-height: 1;
}
div.wd_toolbar-page {
  clear: both;
}
div.wd_toolbar ul.wd_tools {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 5px 0;
}
div.wd_toolbar ul.wd_tools li.wd_tool {
  display: block;
  list-style-type: none;
  margin-right: 5px;
  padding: 0;
  float: right;
}
span.wd_tool_icon > a:link,
span.wd_tool_icon > a:visited {
  border-radius: 2px;
  display: inline-block;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  margin: 0;
  padding: 7px;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  background-color: #767676;
}
span.wd_tool_icon > a:hover {
  color: #ffffff;
  background-color: #666666;
  text-decoration: none;
}
div.wd_toolbar ul.wd_tools li.wd_tool-addthis {
  float: left;
}
.wd_title {
  font-size: 1.3em;
  font-weight: bold;
}
.wd_subtitle {
  font-weight: bold;
}
table.wd_extimagetable {
  border-style: none;
  border-spacing: 0px;
  clear: right;
  float: right;
}
td.wd_extimagecell {
  text-align: right;
  margin: 0px 0px 5px 5px;
  padding: 0px;
}
td.wd_extimagecell img {
  padding-left: 10px;
  padding-bottom: 10px;
  border-style: none;
}
blockquote.wd_pullquote {
  font-style: italic;
  width: 200px;
  margin: 0 0 20px 20px;
  line-height: 1.8em;
  float: right;
  clear: right;
}
blockquote.wd_pullquote:before {
  content: "“ ";
  font-size: xx-large;
}
blockquote.wd_pullquote:after {
  content: " ”";
  font-size: xx-large;
  float: right;
}
.fullscreen-button {
  border: 1px solid #d1d1d1;
  background: #e3e3e3
    url(https://www.endo.com/images/webdriver/view_full_screen_button_background.png)
    no-repeat;
  padding: 3px 3px 3px 30px;
  margin: 5px 0px 5px 0px;
  color: #727272;
  width: 220px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
}
.wd_contact {
  margin: 1em 0;
}
.wd_release_data {
  margin: 1em 0;
}
.wd_caption {
  text-align: center;
  font-size: 0.72em;
  font-style: italic;
  margin: 1em 0 0 0;
}

span.prnews_span {
  font-size: 8pt;
  font-family: "Arial";
  color: black;
}
a.prnews_a {
  color: blue;
}
li.prnews_li {
  font-size: 8pt;
  font-family: "Arial";
  color: black;
}
p.prnews_p {
  font-size: 0.62em;
  font-family: "Arial";
  color: black;
  margin: 0in;
}

span.prnews_span {
  font-size: inherit;
  font-family: inherit;
}
li.prnews_li {
  font-size: inherit;
  font-family: inherit;
}
p.prnews_p {
  font-size: inherit;
  font-family: inherit;
}

div.wd_news_body img {
  margin: 0 0 20px 20px;
  float: right;
  max-width: 250px;
  max-height: 250px;
  width: auto !important;
  height: auto !important;
}

div.PRN_ImbeddedAssetReference {
  display: none;
}
div[id^="prni_dvprne"] {
  display: none;
}

/* Class selector repeated in order to increase specificity */
.wd_sr-only.wd_sr-only.wd_sr-only.wd_sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.wd_sr-only.wd_sr-only.wd_sr-only.wd_sr-only:active,
.wd_sr-only.wd_sr-only.wd_sr-only.wd_sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.wd_sr-only-focusable:active,
.wd_sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

span.prnews_span {
  font-size: 8pt;
  font-family: "Arial";
  color: black;
}
a.prnews_a {
  color: blue;
}
li.prnews_li {
  font-size: 8pt;
  font-family: "Arial";
  color: black;
}
p.prnews_p {
  font-size: 0.62em;
  font-family: "Arial";
  color: black;
  margin: 0in;
}

span.prnews_span {
  font-size: inherit;
  font-family: inherit;
}
li.prnews_li {
  font-size: inherit;
  font-family: inherit;
}
p.prnews_p {
  font-size: inherit;
  font-family: inherit;
}

div.wd_news_body img {
  margin: 0 0 20px 20px;
  float: right;
  max-width: 250px;
  max-height: 250px;
  width: auto !important;
  height: auto !important;
}

div.PRN_ImbeddedAssetReference {
  display: none;
}
div[id^="prni_dvprne"] {
  display: none;
}

span.prnews_span {
  font-size: 8pt;
  font-family: "Arial";
  color: black;
}
a.prnews_a {
  color: blue;
}
li.prnews_li {
  font-size: 8pt;
  font-family: "Arial";
  color: black;
}
p.prnews_p {
  font-size: 0.62em;
  font-family: "Arial";
  color: black;
  margin: 0in;
}
.prngen25 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen28 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 0.5pt solid;
}
.prngen2 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen22 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen31 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen12 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen8 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen13 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 3pt double;
  border-left: black 1pt;
}
.prngen9 {
  padding-right: 0.5em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen27 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 0.5pt solid;
}
.prngen14 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen4 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen11 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen5 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen19 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen6 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen23 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen24 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen21 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 3pt double;
  border-left: black 1pt;
}
.prngen15 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen20 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 3pt double;
  border-left: black 1pt;
}
.prngen3 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen30 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 0.5pt solid;
}
.prngen29 {
  padding-right: 0.83em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 3pt double;
  border-left: black 1pt;
}
.prngen16 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen18 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen33 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen26 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen32 {
  padding-right: 0.5em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: RIGHT;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 3pt double;
  border-left: black 1pt;
}
.prngen17 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 0.5pt solid;
  border-right: black 1pt;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen34 {
  padding-right: 0.33em;
  padding-left: 0.17em;
  vertical-align: TOP;
  font-size: 8pt;
  font-family: ARIAL;
  color: BLACK;
  margin: 0em;
  border-top: black 0pt;
  border-right: black 0pt;
  border-bottom: black 0pt;
  border-left: black 0pt;
}
.prngen7 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  border-top: black 1pt;
  border-right: black 1pt;
  border-bottom: black 1pt;
  border-left: black 1pt;
}
.prngen10 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: TOP;
  border-top: black 0pt;
  border-right: black 0pt;
  border-bottom: black 0pt;
  border-left: black 0pt;
}
.prnml8 {
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
  margin-left: 0.67em !important;
}
.prnml4 {
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
  margin-left: 0.33em !important;
}
.prnml10 {
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
  margin-left: 0.83em !important;
}
.prnbcc {
  border-collapse: COLLAPSE;
  border-top: 1pt black;
  border-right: 1pt black;
  border-bottom: 1pt black;
  border-left: 1pt black;
}
.prnml20 {
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
  margin-left: 1.67em !important;
}

span.prnews_span {
  font-size: inherit;
  font-family: inherit;
}
li.prnews_li {
  font-size: inherit;
  font-family: inherit;
}
p.prnews_p {
  font-size: inherit;
  font-family: inherit;
}

div.wd_news_body img {
  margin: 0 0 20px 20px;
  float: right;
  max-width: 250px;
  max-height: 250px;
  width: auto !important;
  height: auto !important;
}

div.PRN_ImbeddedAssetReference {
  display: none;
}
div[id^="prni_dvprne"] {
  display: none;
}

span.prnews_span {
  font-size: 8pt;
  font-family: "Arial";
  color: black;
}
a.prnews_a {
  color: blue;
}
li.prnews_li {
  font-size: 8pt;
  font-family: "Arial";
  color: black;
}
p.prnews_p {
  font-size: 0.62em;
  font-family: "Arial";
  color: black;
  margin: 0in;
}
.prngen5 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 1pt;
  border-right: black 0.5pt solid;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen3 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  text-align: CENTER;
  border-top: black 0.5pt solid;
  border-right: black 0.5pt solid;
  border-bottom: black 0.5pt solid;
  border-left: black 1pt;
}
.prngen4 {
  padding-right: 0.17em;
  padding-left: 0.17em;
  vertical-align: BOTTOM;
  border-top: black 1pt;
  border-right: black 0.5pt solid;
  border-bottom: black 0.5pt solid;
  border-left: black 0.5pt solid;
}
.prnml4 {
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
  margin-left: 0.33em !important;
}
.prnbsbls {
  border-left: black 0.5pt solid;
}
.prnvab {
  vertical-align: BOTTOM;
}
.prncbts {
  border-top: black 0.5pt solid;
}
.prnbbbs {
  border-bottom: black 0.5pt solid;
}
.prnml10 {
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em;
  margin-left: 0.83em !important;
}
.prnpr2 {
  padding-right: 0.17em;
}
.prnbcc {
  border-collapse: COLLAPSE;
  border-top: 1pt black;
  border-right: 1pt black;
  border-bottom: 1pt black;
  border-left: 1pt black;
}
.prnpl2 {
  padding-left: 0.17em;
}
.prntac {
  text-align: CENTER;
}
.prnbrbrs {
  border-right: black 0.5pt solid;
}

span.prnews_span {
  font-size: inherit;
  font-family: inherit;
}
li.prnews_li {
  font-size: inherit;
  font-family: inherit;
}
p.prnews_p {
  font-size: inherit;
  font-family: inherit;
}

div.wd_news_body img {
  margin: 0 0 20px 20px;
  float: right;
  max-width: 250px;
  max-height: 250px;
  width: auto !important;
  height: auto !important;
}

div.PRN_ImbeddedAssetReference {
  display: none;
}
div[id^="prni_dvprne"] {
  display: none;
}
